<script setup type="module">
import { ref, inject, computed } from 'vue';
import { useRemittanceStore } from '@/stores/remittance';
import { useOptionsStore } from '@/stores/options';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import { wTrans } from 'laravel-vue-i18n';
import { formatAmount } from '@/utils/formatHelper';
import useDownloadMixin from '@/mixins/axiosDownload';
import { useDateFormatter } from '@/composables/dateFormatter';

const { methods: { downloadFromAxiosResponse } } = useDownloadMixin;

const params = ref({
    term: '',
    status: 'All',
    page: 1,
    sort_by: '',
    per_page: 10,
    dealership: null
});

const { formatDateLocal } = useDateFormatter();

const selectedRemittances = ref([]);

const remittanceStore = useRemittanceStore();
const { searchRemittances, destroyRemittance, downloadRemittance } = remittanceStore;
const { all } = storeToRefs(remittanceStore);

const optionsStore = useOptionsStore();
const { dealerships } = storeToRefs(optionsStore);

const userStore = useUserStore();
const { userProfile } = storeToRefs(userStore);

const getDealerships = computed(() => {
    if (userProfile.value.hasRole(['dealership_manager', 'fsm'])) {
        return dealerships.value.filter(dealership => userProfile.value.dealerships.some(userDealership => userDealership.id === dealership.id));
    }
    return dealerships.value;
});

const clearSearch = () => {
    params.value.term = '';
    search();
};

const tableUpdate = ({page, sortBy, itemsPerPage}) => {
    params.value.page = page;
    params.value.sort_by = sortBy;
    params.value.per_page = itemsPerPage;
    search();
};

const search = () => {
    searchRemittances(params.value);
};

const deleteRemittance = async (remittance) => {
    const confirm = await modals.show('ConfirmationModal', {
        props: {
            title: 'Confirm Remittance Removal',
            prompt: 'Delete ' + remittance.name + '?'
        }
    });

    if (confirm) {
        destroyRemittance({
            hash: remittance.hash
        }).then(_ => {
            search();
        });
    }
};

const onSelectRemittance = (remittance) => {
    const index = selectedRemittances.value.findIndex(r => r.id === remittance.id);
    if (index > -1) {
        selectedRemittances.value.splice(index, 1);
    } else {
        selectedRemittances.value.push(remittance);
    }
};

const modals = inject('modals');
const openModal = (modal, mode = 'Create', remittance = null) => {
    if (mode == 'Edit') {
        modals.show(modal, {
            props: {
                remittance,
                mode: 'Edit'
            }
        }).then(_ => {
            search();
        });
    } else {
        modals.show(modal).then(_ => {
            search();
        });
    }
};

const getStatusColour = (status) => {
    if (status.slug == 'paid') {
        return 'green';
    } else if (status.slug == 'unpaid') {
        return 'red';
    } else {
        return 'orange';
    }
};

const applyPayment = (remittance = null) => {
    const selectedRemittance = remittance ? remittance : selectedRemittances.value[0];
    const dealership = selectedRemittance.dealership;
    modals.show('PaymentModal', {
        props: {
            remittance: all.value.data.find(r => r.id === selectedRemittance.id),
            dealership: dealership,
            readonly: !!remittance
        }
    }).then(_ => {
        search();
    });
};

const isRemittanceSelected = (item) => {
    return !!selectedRemittances.value.find(cert => cert.id === item.id);
};

const tableHeaders = ref([
    { title: wTrans('label.id'), key: 'id' },
    { title: wTrans('label.reference'), key: 'reference', value: item => item.payments.length ? item.payments.map(payment => payment.payment_reference).join(', ') : '-' },
    { title: wTrans('label.status'), key: 'global_status', align: 'center' },
    { title: wTrans('label.certs_applied'), key: 'certs_applied', align: 'center' },
    { title: wTrans('label.remittance_total'), key: 'remittance_total', value: item => formatAmount(item.remittance_total) },
    { title: wTrans('label.dealer'), key: 'dealer', value: item => item.dealership.name },
    { title: wTrans('label.date_of_issue'), key: 'date_of_issue', value: item => formatDateLocal(item.created_at) },
]);

const onSelectDealership = () => {
    const selectIndex = tableHeaders.value.findIndex(header => header.key === 'select');
    const hasDealership = !!params.value.dealership;

    if (hasDealership && selectIndex === -1) {
        tableHeaders.value.unshift({ title: wTrans('label.select'), key: 'select', sortable: false });
    } else if (!hasDealership && selectIndex !== -1) {
        tableHeaders.value.splice(selectIndex, 1);
    }

    search();
};

const exportRemittances = () => {
    window.open('/remittances/export');
};

const downloadRemittancePdf = (remittance) => {
    downloadRemittance(remittance).then(response => {
        downloadFromAxiosResponse(response, `certificate_${remittance.id}.pdf`);
    });
};
</script>
<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb />
    </template>
    <template #title>
        Remittances
    </template>
    <template #actions>
        <div class="flex space-x-3">
            <StyledButton v-if="userProfile.hasRole('super_admin', 'admin')" @click="exportRemittances">
                <FontAwesomeIcon
                    icon="file-export"
                    class="mr-1" />
                Remittance Export
            </StyledButton>
            <StyledButton @click="openModal('RemittancesModal')">
                <FontAwesomeIcon
                    icon="plus"
                    class="mr-1" />
                Add Remittance
            </StyledButton>
        </div>
    </template>
    <div class="grid md:grid-cols-3 md:gap-4 md:mb-4">
        <StyledSearch
            v-model="params.term"
            v-debounce:500ms="search"
            name="search"
            :label="$t('label.search')"
            :debounce-events="['input']"
            @click:clear="clearSearch" />
        <StyledDropdown
            v-model="params.status"
            name="status"
            :options="['All', 'Paid', 'Unpaid', 'Cancelled']"
            label="Status"
            @input="search" />
        <StyledDropdown
            v-model="params.dealership"
            name="dealership"
            item-title="name"
            item-value="id"
            :options="getDealerships"
            :label="$t('label.dealership')"
            @input="onSelectDealership" />
    </div>
    <div v-if="params.dealership" class="-mt-6 pb-3">
        <StyledButton :disabled="!selectedRemittances.length" @click="applyPayment()">
            <FontAwesomeIcon
                icon="plus"
                class="mr-1" />
            Apply Payment
        </StyledButton>
    </div>
    <DataTable
        v-if="all"
        :data="all"
        :headers="tableHeaders"
        :actions="[
            { title: $t('label.edit'), icon: 'user-edit', action: (item) => openModal('RemittancesModal', 'Edit', item) },
            { title: $t('label.view_payments'), icon: 'user-edit', action: (item) => applyPayment(item) },
            { title: $t('label.delete'), icon: 'user-times', action: (item) => deleteRemittance(item) },
            { title: $t('label.download_emittance'), icon: 'download', action: item => downloadRemittancePdf(item) }
        ]"
        @table-update="tableUpdate">
        <template #[`item.global_status`]="{ item }">
            <div class="flex justify-center">
                <vChip :color="getStatusColour(item.global_status)">{{ item.global_status.name }}</vChip>
            </div>
        </template>
        <template #[`item.certs_applied`]="{ item }">
            <div class="flex justify-center">
                <vChip :color="item.certificates.length ? 'green' : 'red'">{{ item.certificates.length ? 'Yes' : 'No' }}</vChip>
            </div>
        </template>
        <template #[`item.select`]="{ item }">
            <div class="flex items-center">
                <StyledCheckbox
                    type="checkbox"
                    :model-value="isRemittanceSelected(item)"
                    class="hide-details"
                    @input="onSelectRemittance(item)" />
            </div>
        </template>
    </DataTable>

</MainContentLayout>
</template>

